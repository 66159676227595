import algoliasearch from 'algoliasearch'
const showUnbuilt =
  process.env.GATSBY_PREVIEW === 'TRUE' ||
  process.env.GATSBY_ALGOLIA_INDEX_NAME_PREFIX === 'dev_'

class AlgoliaFilters {
  constructor(settings) {
    this.settings = settings
    this.indexName = `${process.env.GATSBY_ALGOLIA_INDEX_NAME_PREFIX}_posts`
    this.algoliasearch = this.initClient()
    this.index = this.algoliasearch.initIndex(this.indexName)
    this.types = settings.types
  }

  getCategories(cb) {
    let facetFilters = this.types.map(type => {
      return `type:${type}`
    })

    this.index.searchForFacetValues(
      {
        facetName: 'type',
        facetQuery: '*',
        query: '*',
        filters: '',
        facetFilters,
      },
      (err, results) => {
        if (err) {
          cb(err)
        }

        let categories = results.facetHits.map(category => {
          return { value: category.value, label: category.value }
        })

        // console.log(categories, '<< Categories')

        cb(null, categories)
      }
    )
  }

  initClient() {
    let key = process.env.GATSBY_ALGOLIA_SEARCH_API_KEY,
      id = process.env.GATSBY_ALGOLIA_APP_ID

    return algoliasearch(id, key)
  }
}

export default AlgoliaFilters
