import algoliasearch from 'algoliasearch'
const showUnbuilt =
  process.env.GATSBY_PREVIEW === 'TRUE' ||
  process.env.GATSBY_ALGOLIA_INDEX_NAME_PREFIX === 'dev_'

class AlgoliaIndex {
  constructor(settings) {
    this.settings = settings
    this.indexName = `${process.env.GATSBY_ALGOLIA_INDEX_NAME_PREFIX}_posts`
    this.algoliasearch = this.initClient()
    this.types = settings.types
    this.region = settings.region
    this.dateRange = settings.dateRange || false
  }

  search(page = 0, cb) {
    const types = this.types.map(type => {
      return `type:${type}`
    })

    const facetFilters = [
      ...types,
      this.region ? `region.label: ${this.region}` : '',
    ]

    let search = {
      indexName: this.indexName,
      params: {
        offset:
          page === 0
            ? 0
            : page * this.settings.hitsPerPage + this.settings.pageOneExtraHits,
        length:
          page === 0
            ? this.settings.pageOneExtraHits + this.settings.hitsPerPage
            : this.settings.hitsPerPage,
        facetFilters,
        filters: '',
      },
    }

    if (this.dateRange) {
      search.params['filters'] = this.dateRange
    }

    this.algoliasearch.search([search], cb)
  }

  updateFilters(array) {
    this.types = array
  }

  updateDateRage(dateRangeQuery) {
    this.dateRange = dateRangeQuery
  }

  updateRegion(region) {
    this.region = region
  }

  initClient() {
    let key = process.env.GATSBY_ALGOLIA_SEARCH_API_KEY,
      id = process.env.GATSBY_ALGOLIA_APP_ID

    return algoliasearch(id, key)
  }

  results() {
    this.search()
  }
}

export default AlgoliaIndex
