import React from 'react'
import Calendar from 'react-calendar/dist/entry.nostyle'
import Events from './Event'
import { Link } from 'gatsby'

import algoliasearch from 'algoliasearch'
import moment from 'moment'

import {
  DayTile,
  CalendarWrap,
  CalendarControl,
  EventIndexControls,
} from './style'
import { IconButton } from '../IconButton'
import { ReactComponent as Arrow } from '../../images/down-arrow.svg'
import { ReactComponent as GridIcon } from '../../images/grid.svg'
import { ComponentContainer } from '../../utils/globalStyledComponents'

const indexPrefix = process.env.GATSBY_ALGOLIA_INDEX_NAME_PREFIX

class EventPage extends React.Component {
  constructor(props) {
    super(props)
    this.algoliaSearchClient = this.initClient()
    this.index = this.algoliaSearchClient.initIndex(`${indexPrefix}_posts`)
    this.state = {
      value: new Date(),
      events: {},
      categories: [],
      category: null,
    }
  }

  componentDidMount() {
    this.getMonthEvents(moment(this.state.value))
    this.getEventCategories()
  }

  getMonthEvents(date) {
    //accepts moment object
    let dateFrom = date.startOf('month').format('X')
    let dateTo = date.endOf('month').format('X')

    let facetFilters = ['type:Events']

    if (this.state.category) {
      facetFilters.push(`type:${this.state.category}`)
    }

    this.algoliaSearchClient.search(
      [
        {
          indexName: `${indexPrefix}_posts`,
          params: {
            hitsPerPage: 1000,
            filters: `event_dates:${dateFrom} TO ${dateTo}`,
            facetFilters,
          },
        },
      ],
      (err, response) => {
        if (err) {
          return console.log('error', err)
        }

        let data = this.parseEvents(response.results)
        this.setState({
          events: data,
          value: date.toDate(),
        })
      }
    )
  }

  parseEvents(data) {
    let dayTiles = {}

    data[0].hits.forEach(evt => {
      evt.event_dates.forEach(date => {
        // console.log('hi', evt.event_dates.length)
        let dp = moment(date * 1000).format('YYYYMMDD') * 1

        // console.log(evt)

        const alreadyContainsThisEvent = dayTiles[dp]?.some(
          e => e.databaseId === evt.databaseId
        )

        if (alreadyContainsThisEvent) {
          return
        }

        dayTiles[dp]
          ? dayTiles[dp].push({
              title: evt.post_title,
              content: evt.content,
              img: evt.img,
              databaseId: evt.databaseId,
              permalink: evt.permalink,
            })
          : (dayTiles[dp] = [
              {
                title: evt.post_title,
                content: evt.content,
                img: evt.img,
                databaseId: evt.databaseId,
                permalink: evt.permalink,
              },
            ])
      })
    })

    return dayTiles
  }

  updateCategory(e) {
    this.setState(
      {
        category: e.value,
      },
      () => {
        this.getMonthEvents(moment(this.state.value))
      }
    )
  }

  getEventCategories() {
    this.index.searchForFacetValues(
      {
        facetName: 'type',
        facetQuery: '*',
        query: '*',
        facetFilters: ['type:Events'],
      },
      (err, results) => {
        if (err) {
          return console.log(err)
        }

        let categories = results.facetHits.map(category => {
          return { value: category.value, label: category.value }
        })

        this.setState({
          categories,
        })
      }
    )
  }

  renderTileContent(props) {
    let date = moment(props.date)
    let dp = date.format('YYYYMMDD')
    let events = this.state.events[dp]

    return props.date.getMonth() === this.state.value.getMonth() ? (
      <DayTile>
        <div className="day">{date.format('DD')}</div>
        <Events date={date} events={events} />
      </DayTile>
    ) : (
      <DayTile className="inactive">
        <div className="day">{date.format('DD')}</div>
      </DayTile>
    )
  }

  nextMonth() {
    let nextMonth = moment(this.state.value).add(1, 'M')
    this.getMonthEvents(nextMonth)
  }

  previousMonth() {
    let prevMonth = moment(this.state.value).subtract(1, 'M')
    this.getMonthEvents(prevMonth)
  }

  initClient() {
    let key = process.env.GATSBY_ALGOLIA_SEARCH_API_KEY,
      id = process.env.GATSBY_ALGOLIA_APP_ID

    return algoliasearch(id, key)
  }

  render() {
    return (
      <ComponentContainer>
        <div style={{ margin: '0 auto' }}>
          <EventIndexControls>
            <p>
              Are you an event organiser?{' '}
              <Link to="/whats-on/register-your-event/">
                {' '}
                Register your event
              </Link>
            </p>
            <IconButton
              back
              icon={GridIcon}
              label="Back to grid view"
              click={this.props.toggleCalendar}
            />
          </EventIndexControls>
          <CalendarControl>
            <button
              className="previous"
              onClick={this.previousMonth.bind(this)}
            >
              <Arrow />
            </button>
            <button className="next" onClick={this.nextMonth.bind(this)}>
              <Arrow />
            </button>
            <div className="currentMonth">
              {moment(this.state.value).format('MMMM YYYY')}
            </div>
          </CalendarControl>
          <CalendarWrap>
            <Calendar
              tileContent={this.renderTileContent.bind(this)}
              maxDetail="month"
              minDetail="month"
              // calendarType="US"
              value={this.state.value}
            />
          </CalendarWrap>
        </div>
      </ComponentContainer>
    )
  }
}

export default EventPage
