import styled from 'styled-components';

export const IconButton = styled.button`
  border: 1px solid #545C52;
  background: transparent;
  display: flex;
  align-items: center;
  height: 64px;
  font-family: "Maison Neue", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  color: #212C1F;
  padding: 0;
  flex: 1;
  padding-left: 22px;
  outline: none;
  font-size: 16px;
  overflow: hidden;
  min-width: 370px;

  @media(min-width: 1024px) {
    min-width: 100%;
  }
  span {
    flex-grow: 1;
    text-align: left;
    font-size: 16px;
  }


  .icon {
    border-left: 1px solid #889390;
    width: 64px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    height: 64px;

    svg {
      width: 24px;
      height: 24px;
      stroke: #a55331;
    }

  }

  .back {
    flex-grow: 0;
    width: 12px;
    transform: rotate(90deg);
    transform-origin: 50% 50%;
    margin: -3px 12px 0 0;
  }
`