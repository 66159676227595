import React from 'react';
import { IconButton } from './style.js'
import { ReactComponent as Arrow } from '../../images/down-arrow.svg';

const Button = (props) => {
    return (
      <IconButton {...props} onClick={props.click}>
        { props.back && <Arrow className="back" /> }
        <span>
          { props.label }
        </span>
        <div  className="icon">
          { React.createElement(props.icon) }
        </div>
      </IconButton>
    );
}

export default Button;