import React, { Component } from 'react'
import moment from 'moment'

import AlgoliaIndex from '../../utils/algoliaIndex'
import AlgoliaFilters from '../../utils/algoliaFilters'

import { Teaser } from '../Teaser'
// import { Unearth } from '../Unearth';
import { MoreResults } from '../MoreResults'
import { IconButton } from '../IconButton'
import { ReactComponent as CalendarIcon } from '../../images/calendar.svg'
import { ReactComponent as Arrow } from '../../images/down-arrow.svg'
import SelectComponent from '../Select/selectComponent'
import slugify from 'slugify'

import {
  ComponentContainer,
  GridWrapper,
  FullWidthWrapper,
  Toolbar,
  MobileRefine,
  MobileToggle,
} from './style.js'
import { toLower } from 'lodash'

const datenow = moment({})
const forever = moment('2099-01-01')

const DateRanges = [
  {
    slug: 'all',
    label: 'All',
    value: `event_dates:${datenow
      .startOf('day')
      .format('X')} TO ${forever.startOf('day').format('X')}`,
  },
  {
    slug: 'this-week',
    label: 'This Week',
    value: `event_dates:${datenow
      .startOf('day')
      .format('X')} TO ${datenow.endOf('week').format('X')}`,
  },
  {
    slug: 'this-month',
    label: 'This Month',
    value: `event_dates:${datenow
      .startOf('day')
      .format('X')} TO ${datenow.endOf('month').format('X')}`,
  },
  {
    slug: 'next-month',
    label: 'Next Month',
    value: `event_dates:${datenow
      .add(1, 'M')
      .startOf('month')
      .format('X')} TO ${datenow
      .add(1, 'M')
      .endOf('month')
      .format('X')}`,
  },
]

const getValue = (opts, val) => opts.find(o => o.slug === val)

class WhatsOn extends Component {
  constructor(props) {
    super(props)

    this.algoliaIndex = new AlgoliaIndex({
      types: ['Events'],
      dateRange: DateRanges[0].value,
      hitsPerPage: 9,
      pageOneExtraHits: 1, //Extra results for first "page"
    })

    this.algoliaFilters = new AlgoliaFilters({
      types: ['Events'],
    })

    this.state = {
      featuredEvent: false,
      eventItems: [],
      loadUnearth: false,
      currentPage: 0,
      moreResults: false,
      rangeFilter: DateRanges[0],
      categories: [{ value: 'Events', label: 'All' }],
      filter: { value: 'Events', label: 'All' },
      region: { value: 'all', label: 'All' },
      refinements: false,
    }
  }

  componentWillReceiveProps() {
    const urlParams = new URLSearchParams(window.location.search)
    const filter = urlParams.get('c')
    const daterange = urlParams.get('d')

    if (daterange) {
      this.dateFilter(daterange)
    }

    if (filter) {
      this.categoryFilter(filter)
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const filter = urlParams.get('c')
    const daterange = urlParams.get('d')
    const view = urlParams.get('v')
    const region = urlParams.get('r')

    if (view === 'calendar') {
      this.props.toggleCalendar()
      window.history.replaceState(null, null, window.location.pathname)
    }

    if (!daterange && !filter) {
      this.search()
    }

    if (daterange) {
      this.dateFilter(daterange)
    }

    if (filter) {
      this.setState({ filter })
    }
    if (region) {
      this.setState({ region: { value: region, label: region } })
      this.algoliaIndex.updateRegion(toLower(region))
      this.search()
    }

    this.categoryFilter(filter)
  }

  dateFilter(daterange) {
    let dr = getValue(DateRanges, daterange)
    if (dr) {
      DateRanges.map(range => {
        if (daterange === range.slug) {
          this.algoliaIndex.updateDateRage(dr.value)
          this.setState({ rangeFilter: dr })
        }
      })
    }
    this.search()
  }
  categoryFilter(filter) {
    this.algoliaFilters.getCategories((err, categories) => {
      //slugify every category
      if (categories) {
        let filteredcats = categories.filter(cat => {
          if (cat.value === 'Events') {
            cat.label = 'All'
          }

          cat.value = slugify(cat.value, {
            lower: true,
            remove: /[*+~.()&'"!:@]/g,
          })
          return cat
        })

        //matches filter to a specific category and performs the search accordingly
        filteredcats.map(category => {
          if (filter === category.value) {
            this.setState({
              filter: { value: category.value, label: category.label },
            })
            this.algoliaIndex.updateFilters(['Events', category.label])
            this.search()
          }
        })

        //populates dropdownlist
        this.setState({ categories: filteredcats })
      }
    })
  }

  moreResults() {
    let thisPage = this.state.currentPage + 1
    this.algoliaIndex.search(thisPage, (err, results) => {
      if (err) {
        return console.log(err)
      }

      let { length, offset, nbHits } = results.results[0]

      this.setState({
        eventItems: [...this.state.eventItems, ...results.results[0].hits],
        currentPage: thisPage,
        moreResults: offset + length < nbHits,
      })
    })
  }

  changeRange(rangeFilter) {
    this.setState({
      rangeFilter,
    })
    this.algoliaIndex.updateDateRage(rangeFilter.value)
    this.search()
  }

  updateFilter(filter) {
    if (window.location.search) {
      window.history.replaceState(
        null,
        null,
        `${window.location.pathname}?c=${filter.value}`
      )
    } else {
      window.history.replaceState(
        null,
        null,
        `${window.location.pathname}?c=${filter.value}`
      )
    }

    this.setState({ filter })
    this.algoliaIndex.updateFilters([
      'Events',
      filter.value === 'events' ? 'Events' : filter.label,
    ])
    this.search()
  }

  updateRegion(region) {
    if (window.location.search) {
      window.history.replaceState(
        null,
        null,
        `${window.location.pathname}?r=${region.value}`
      )
    } else {
      window.history.replaceState(
        null,
        null,
        `${window.location.pathname}?r=${region.value}`
      )
    }
    this.setState({ region })
    this.algoliaIndex.updateRegion(region.value)
    this.search()
  }

  search() {
    this.algoliaIndex.search(0, (err, results) => {
      if (err) {
        return console.log(err)
      }

      let { hits, length, offset, nbHits } = results.results[0]
      let initalResults = [...hits]
      let featuredEvent = initalResults.splice(0, 1)[0]
      let eventItems = [...initalResults]
      this.setState({
        eventItems,
        featuredEvent,
        loadUnearth: true,
        currentPage: 0,
        moreResults: offset + length < nbHits,
      })
    })
  }

  toggleRefinements() {
    this.setState({
      refinements: !this.state.refinements,
    })
  }
  render() {
    let { featuredEvent, eventItems } = this.state
    const regionOptions = [
      { value: null, label: 'All' },
      ...this.props.regions.map(region => {
        return { value: region.title, label: region.title }
      }),
    ]

    return (
      <ComponentContainer>
        <MobileRefine show={this.state.refinements}>
          <MobileToggle onClick={this.toggleRefinements.bind(this)}>
            <Arrow /> Refine Results
          </MobileToggle>
          <Toolbar>
            <SelectComponent
              placeholder="Category"
              value={this.state.filter}
              options={this.state.categories}
              onChange={this.updateFilter.bind(this)}
            />
            <SelectComponent
              placeholder="Date Range"
              value={this.state.rangeFilter}
              options={DateRanges}
              onChange={this.changeRange.bind(this)}
            />
            <SelectComponent
              placeholder="Region"
              value={this.state.region}
              options={regionOptions}
              onChange={this.updateRegion.bind(this)}
            />
            <IconButton
              className="switch"
              icon={CalendarIcon}
              label="Calendar View"
              click={this.props.toggleCalendar}
            />
          </Toolbar>
        </MobileRefine>
        {featuredEvent && (
          <FullWidthWrapper>
            <Teaser
              categories={featuredEvent.type.slice(1, 4)}
              event
              fullWidth
              featured
              whatson
              openInNewTab
              key={featuredEvent.post_id}
              url={featuredEvent.permalink}
              region={featuredEvent.region}
              date={featuredEvent.event_dates}
              title={featuredEvent.post_title}
              desc={featuredEvent._snippetResult.content.value}
              placeholderCategories={featuredEvent.type}
              databaseId={featuredEvent.databaseId}
              img={{
                ...featuredEvent.img,
                sizes: '500px',
              }}
            />
          </FullWidthWrapper>
        )}
        <GridWrapper>
          {eventItems &&
            eventItems.map((event, index) => (
              <Teaser
                categories={event.type.slice(1, 4)}
                event
                openInNewTab
                key={index}
                standard
                url={event.permalink}
                date={event.event_dates}
                region={event.region}
                title={event.post_title}
                databaseId={event.databaseId}
                desc={event._snippetResult.content.value}
                placeholderCategories={event.type}
                img={{
                  ...event.img,
                  sizes: '500px',
                }}
              />
            ))}
        </GridWrapper>

        {this.state.moreResults && (
          <MoreResults
            moreResults={this.moreResults.bind(this)}
            label="results"
            events
          />
        )}

        {/* {this.state.loadUnearth && <Unearth />} */}
      </ComponentContainer>
    )
  }
}

export default WhatsOn
