import styled from 'styled-components';
export const ComponentContainer = styled.div`
	max-width: 1440px;

	margin: 0px auto;
	padding: ${(props) => props.theme.globalPaddingMobile};

	@media (min-width: 768px) {
	padding: 0 30px;
	}

	@media (min-width: 1280px) {
		padding: ${(props) => props.theme.globalPaddingDesktop};
	}
`;

export const OverflowContainer = styled.div`overflow: hidden;`;

export const SubText = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	@media (min-width: 768px) {
		flex-direction: row;
	}

	@media (min-width: 1024px) {
	}

	h4 {
		font-family: 'Freight Big';
		font-size: 24px;
		text-transform: none;
		margin-bottom: 0;

		@media (min-width: 768px) {
			width: 200px;
			font-size: 32px;
			margin-right: 20px;
		}

		@media (min-width: 1024px) {
			width: 310px;
			margin-right: 110px;
		}
	}
`;

export const GridWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 10px;
	grid-auto-flow: row;
	justify-content: center;
	padding-bottom: 20px;
	overflow:hidden;

	border-bottom: 1px solid black;

	> div:last-of-type {
		> div {
			::before {
				display: none;

				@media (min-width: 768px) {
					display: block;
				}
			}
		}
	}

	@media (min-width: 480px) {
		grid-template-columns: 1fr 1fr;
	}

	@media (min-width: 768px) {
		border: none;

		grid-template-columns: 1fr 1fr 1fr;
		padding-bottom: 0px;
		grid-column-gap: 30px;
	}

	@media (min-width: 1280px) {
		grid-column-gap: 55px;
	}
`;

export const FullWidthWrapper = styled.div`
	/* padding-top:20px; */
	overflow:hidden;

	@media (min-width: 768px) {
		/* padding-top: 30px; */
	}

	@media (min-width: 1280px) {
		/* padding-top: 50px; */
	}
`;

export const Toolbar = styled.div`
	margin: 0 20px;

	.switch {
		display: none;
	}

	@media (min-width: 768px) {
		margin: 0;
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: 24px;
		justify-items: center;
		margin-bottom: 30px;
		padding-bottom: 20px;
		border-bottom: 1px solid #212c1f;

		.switch {
			display: flex;
		}
	}

	@media (min-width: 1024px) {
		padding-bottom: 10px;
		grid-template-columns: 1fr 1fr;
	}

	@media (min-width: 1280px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;

		padding-bottom: 30px;
		margin-bottom: 50px;
	}

	@media (min-width: 1440px) {
		padding-bottom: 50px;
	}
`;

export const MobileToggle = styled.div`
	border-bottom: 1px solid #889390;
	display: flex;
	align-items: center;
	/* height: 64px; */
	font-family: "Maison Neue", sans-serif;
	font-weight: 700;
	text-transform: uppercase;
	cursor: pointer;
	color: #212c1f;
	padding: 0;
	flex: 1;
	outline: none;
	font-size: 16px;
	margin-bottom: 20px;
	padding-bottom: 20px;

	svg {
		margin-right: 14px;
	}

	@media (min-width: 768px) {
		display: none;
	}
`;

export const MobileRefine = styled.div`
	${MobileToggle} {
		svg {
			width: 16px;
			transform: ${(props) => (props.show ? 'rotate(180deg)' : 'rotate(0)')};
		}
	}

	${Toolbar} {
		display: ${(props) => (props.show ? 'grid' : 'none')};
		justify-content: center;

		@media (min-width: 768px) {
			display: grid;
			
		}
	}
`;
