import styled from 'styled-components'
import Select from 'react-select'

export const DayTile = styled.div`
  flex-grow: 1;
  flex: 1;
  padding: 20px;
  position: relative;
  border-bottom: 1px solid #b9bab4;
  border-right: 1px solid #b9bab4;
  transition: background 0.2s ease-out, box-shadow 0.2s ease-out;

  &:hover {
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    .day {
      color: #8b3a19;
    }

    &:before {
      content: ' ';
      display: block;
      height: 5px;
      width: 100%;
      background: #8b3a19;
      position: absolute;
      top: -2px;
      left: 0;
    }
  }

  .day {
    font-size: 48px;
    font-family: 'Freight Big', serif;
    font-weight: 400;
    color: #212c1f;
    justify-content: flex-start;
    display: flex;
  }

  &.inactive {
    &:before {
      display: none;
    }

    .day {
      color: #b9bab4;
    }

    &:hover {
      background: inherit;
      box-shadow: inherit;
    }
  }
`

export const CalendarControl = styled.div`
  display: flex;
  background: #212c1f;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  border-bottom: 1px solid #889390;
  align-content: center;

  .currentMonth {
    display: flex;
    align-items: center;
    text-indent: 20px;
  }

  button {
    height: 80px;
    background: transparent;
    border: 0;
    outline: none;
    border-right: 1px solid #889390;
    flex-basis: calc(14.2857% / 2);
    max-width: calc(14.2857% / 2);
    cursor: pointer;

    &:hover {
      background: #000;
    }

    svg * {
      fill: #fff;
    }

    &.previous {
      svg {
        transform: rotate(90deg);
        width: 16px;
      }
    }

    &.next {
      svg {
        transform: rotate(-90deg);
        width: 16px;
      }
    }
  }

  .currentMonth {
    display: flex;
    flex: 1;
    padding: 10px;
  }
`

export const EventImage = styled.div`
  background: #fff;
  position: absolute;
  height: 100%;
  max-height: 200px;
  width: calc(100% + 1px);
  top: 0;
  left: 100%;
  z-index: 1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: opacity 0.2s ease-out;
  opacity: 0;
  visibility: hidden;
  width: 200px;
  height: 150px;
  top: -12px;
  right: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const EventItem = styled.a`
  font-size: 14px;
  color: #545c52;
  font-family: 'Maison Neue', sans-serif;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  border-bottom: 1px solid #b9bab4;
  padding: 5px 0;
  list-style: none;
  text-align: left;
  position: relative;

  &:hover {
    color: #8b3a19;
  }

  &:last-of-type {
    border: none;
  }

  &:hover ${EventImage} {
    visibility: visible;
    opacity: 1;
  }
`

export const CalendarWrap = styled.div`
  .react-calendar__navigation {
    display: none !important;
  }

  .react-calendar__tile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: visible !important;
    outline: none;

    &:nth-child(7n) {
      ${EventImage} {
        /* width: calc(100% + 2px); */
        /* left: calc(-100% - 2px); */
        left: -210px;
      }
    }

    abbr {
      display: none;
    }

    padding: 0;
    border: none;
    background: transparent;
  }

  .react-calendar__month-view__days {
    border-left: 1px solid #b9bab4;
  }

  .react-calendar__month-view__weekdays {
    background: #212c1f;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 20px;
    color: #fff;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 15px;
    border-right: 1px solid #889390;

    &:last-child {
      border-right: none;
    }
  }
`

export const EventList = styled.ul`
  padding: 0;
  margin: 0;
`

export const Toggle = styled.div`
  color: #212c1f;
  font-family: 'Maison Neue', sans-serif;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  margin-top: 15px;
  cursor: pointer;

  &:hover {
    color: #8b3a19;

    svg * {
      stroke: #8b3a19;
    }
  }

  svg {
    width: 12px;
    margin-left: 10px;
    transform: ${props => (props.open ? 'scaleY(-1)' : 'none')};
    margin-top: ${props => (props.open ? '-4px' : 0)};

    * {
      stroke: #212c1f;
    }
  }
`

export const EventIndexControls = styled.div`
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: auto 1fr 362px;
  grid-template-areas: 'text ... button';
  grid-column-gap: 55px;

  > * {
    grid-area: button;
  }

  > p {
    grid-area: text;
    font-size: 14px;
    margin: 0;
    display: flex;
    align-items: center;

    a {
      font-weight: 500;
      margin-left: 4px;
      :hover {
        color: #a55331;
      }
    }
  }
`

export const StyledSelect = styled(Select)`
  flex: 1;
  height: 64px;
  border: 1px solid #545c52;
`
