import React from 'react'
import { EventList, EventItem, EventImage, Toggle } from './style'
import { ReactComponent as Arrow } from '../../images/down-arrow.svg'
import { ResponsiveImage } from '../ResponsiveImage'
import parse from 'html-react-parser'

const Event = props => {
  return (
    <EventItem target="_blank" href={props.permalink}>
      {parse(props.title)}
      <EventImage>
        {props.img ? (
          <ResponsiveImage
            src={props.img.url}
            sizes={props.img.sizes}
            srcSet={props.img.srcSet}
            alt={props.title}
          />
        ) : (
          <img
            src="https://res.cloudinary.com/avenue/image/upload/v1637111400/vb-placeholder_kwrhkq.png"
            alt="teaserimg"
          />
        )}
      </EventImage>
    </EventItem>
  )
}

export default class Events extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  toggleMore() {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    let { events, date } = this.props
    let eventsTrimmed = events && events.slice(0, !this.state.open ? 3 : 999)
    let toggleText = events
      ? this.state.open
        ? 'Close'
        : `${events.length - 3} more`
      : null

    return (
      <>
        <EventList>
          {eventsTrimmed &&
            eventsTrimmed.map((evt, index) => {
              return (
                <Event
                  {...evt}
                  key={`${evt.title} , ${date._d}, ${index}`}
                  title={evt.title}
                />
              )
            })}
        </EventList>
        {events && events.length > 3 && (
          <Toggle open={this.state.open} onClick={this.toggleMore.bind(this)}>
            {toggleText} <Arrow />
          </Toggle>
        )}
      </>
    )
  }
}
