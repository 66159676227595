import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { WhatsOnPage } from '../components/WhatsOnPage'
import { Calendar } from '../components/Calendar'
import { graphql } from 'gatsby'

class WhatsOn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      calendar: false,
    }

    this.removeCalendar = this.removeCalendar.bind(this)
  }

  componentDidMount() {
    this.mediaQuery = window.matchMedia('(max-width: 1024px)')
    this.mediaQuery.addListener(this.removeCalendar)
  }

  componentWillUnmount() {
    this.mediaQuery.removeListener(this.removeCalendar)
  }

  switchView() {
    this.setState({
      calendar: !this.state.calendar,
    })
  }

  removeCalendar() {
    if (this.mediaQuery.matches && this.state.calendar) {
      this.setState({
        calendar: false,
      })
    }
  }

  render() {
    const data = this.props.data

    return (
      <Layout headerTitle="What's On" generic>
        <SEO
          title={data.page.seo.title}
          description={data.page.seo.metaDesc}
          image={data.page.avContent.featureImg?.sourceUrl}
        />

        {this.state.calendar ? (
          <Calendar toggleCalendar={this.switchView.bind(this)} />
        ) : (
          <WhatsOnPage
            toggleCalendar={this.switchView.bind(this)}
            regions={data?.allWpRegion?.nodes}
          />
        )}
      </Layout>
    )
  }
}

export default WhatsOn

export const pageQuery = graphql`
  query($id: String!) {
    allWpRegion {
      nodes {
        title
      }
    }
    page: wpPage(id: { eq: $id }) {
      title
      seo {
        title
        metaDesc
      }

      avContent {
        featureImage {
          srcSet
          sourceUrl
        }
      }
    }
  }
`
